import Vue from 'vue'

import router from './router'
import store from './store'

Vue.config.productionTip = false

/** 引入tailwind.css */
import './tailwind.css'

/** 全局安装element-ui。注意element css与 App的顺序 */
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css' // 断点隐藏，参考 https://element.eleme.cn/#/zh-CN/component/layout
Vue.use(ElementUI, { size: 'medium' })

/** 安装全局filters */
import Filters from '@/libs/vue-filters/install'
Vue.use(Filters)

// import './filters/index.js'

/** 安装全局组件 */
import ComponentIcon from '@/components/icon/icon.vue'
Vue.component('Icon', ComponentIcon)

/** 全局混入 */
import GlobalMixin from '@/libs/vue-mixins/global-mixin'
Vue.mixin(GlobalMixin)

/** 挂载工具函数 */
import Tools from '@/libs/vue-tools/install'
Vue.use(Tools)
import { deepClone } from '@/utils/index'
import TableHead from '@/components/table/table-head.vue'
import tableHeadForTwo from '@/components/table/tableHead.vue'
import TablePagination from '@/components/table/table-pagination.vue'
import FormDialog from '@/components/form/form-dialog.vue'
import UploadImage from '@/components/upload/upload-image.vue'
import draggable from 'vuedraggable'

Vue.component('draggable', draggable)
Vue.component('TableHead', TableHead)
Vue.component('tableHeadForTwo', tableHeadForTwo)
Vue.component('TablePagination', TablePagination)
Vue.component('FormDialog', FormDialog)
Vue.component('UploadImage', UploadImage)

import App from './App.vue'
const app = new Vue({
	router,
	store,
	render: (h) => h(App),
})
app.$mount('#app')
