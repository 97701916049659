var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-container',{staticClass:"vi-main h-screen"},[_c('el-aside',{staticClass:"h-screen scroll-bar",style:({
			width: ((_vm.is_mini_mode
					? _vm.layout.sider_collapse
						? 0
						: _vm.layout.sider_collapse_width
					: _vm.layout.sider_collapse
					? _vm.layout.sider_collapse_width
					: _vm.layout.sider_width) + "px"),
			backgroundColor: _vm.layout.sider_color,
			color: _vm.layout.sider_text_color,
			transition: 'width 0.2s',
		})},[_c('div',{staticClass:"logo-box overflow-hidden flex-box justify-center sticky top-0 border-b border-gray-900",style:({
				height: ((_vm.layout.navbar_height) + "px"),
			})},[_c('div',[_vm._v("万澳会")]),(!_vm.layout.sider_collapse && !_vm.is_mini_mode)?_c('div',[_vm._v("管理平台")]):_vm._e()]),_c('SiderMenu',{attrs:{"layout":_vm.layout,"miniMode":_vm.is_mini_mode}})],1),_c('el-container',{staticClass:"bg-white"},[_c('el-header',{staticClass:"flex shadow",style:({ height: ((_vm.layout.navbar_height) + "px"), padding: '0' })},[_c('NavBar',{attrs:{"sider-collapse":_vm.layout.sider_collapse,"mini-mode":_vm.is_mini_mode},on:{"refresh":function($event){;(_vm.refresh.is_hide = true), _vm.$nextTick(function () { return (_vm.refresh.is_hide = false); })},"sider-collapse":function($event){_vm.layout.sider_collapse = $event}}})],1),_c('el-main',{staticClass:"bg-gray-100",staticStyle:{"padding":"0"}},[(!_vm.refresh.is_hide)?_c('keep-alive',[(_vm.$route.meta.keepalive)?_c('router-view'):_vm._e()],1):_vm._e(),(!_vm.refresh.is_hide && !_vm.$route.meta.keepalive)?_c('router-view'):_vm._e(),_c('el-footer',{staticStyle:{"padding":"0","height":"auto"}},[_c('div',{staticClass:"text-center py-4 text-gray-400 text-sm leading-none"},[_vm._v("CopyRight 2021-2023")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }