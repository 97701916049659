import { get as lodashGet } from 'lodash'
import dayjs from 'dayjs'

/**
 * 方便在模板上访问对象深层属性
 * @example <div>{{user | getObject('book.name', '空')}}</div>
 */
export const getObject = lodashGet

/**
 * 格式化时间
 * @param time 时间
 * @param invalidMsg 时间非法时返回的字符
 * @param format 格式化方法
 */
export const formatTime = (time, invalidMsg = '-', format = 'YYYY-MM-DD HH:mm:ss') => {
	let d
	if (typeof time == 'number') d = dayjs(time > 9999999999 ? time : time * 1000)
	else d = dayjs(time)

	return d.isValid() ? d.format(format) : invalidMsg
}
