export type hotelOrderState = {
	key: string | number
	value: string | number
}

export type State = {
	hotelOrderState: hotelOrderState[]
	mealGoodsState: hotelOrderState[]
	balanceOrderState: hotelOrderState[]
	remarkState: hotelOrderState[]
}

const state: State = {
	hotelOrderState: [
		{ key: 99, value: '待付款' },
		{ key: 1, value: '待入住' },
		{ key: 2, value: '已入住' },
		{ key: 3, value: '已完成' },
		{ key: 4, value: '已取消' },
	],
	remarkState: [
		{ key: 1, value: '未点评' },
		{ key: 2, value: '已点评' },
	],
	mealGoodsState: [
		{ key: 1, value: '待支付' },
		{ key: 2, value: '已支付' },
		{ key: 3, value: '已取消' },
	],
	balanceOrderState: [
		{
			key: 0,
			value: '已提交',
		},
		{
			key: 1,
			value: '已打款',
		},
		{
			key: 2,
			value: '未通过',
		},
	],
}
const mutations = {}
const actions = {}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
}
