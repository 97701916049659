import { CreateElement, VNode } from 'vue'
import { Location } from 'vue-router'
import Icon from '@/components/icon/icon.vue'

/** 全局左侧菜单 */
const menus: Menu[] = [
	{
		name: '首页',
		icon: 'icon-shouye',
		route: 'home',
	},
	{
		name: '酒店管理',
		icon: 'icon-jiudian',
		children: [
			{
				name: '酒店列表',
				route: 'hotel_list',
				// iconfont图标项目在李立民的帐号下，先同一用一样的，全部弄完了一次性补齐
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
			{
				name: '标签列表',
				route: 'hotel_label_list',
				icon: (h) => h(Icon, { props: { name: 'icon-24gl-tags', size: 18 } }),
			},
			{
				name: '设施列表',
				route: 'hotel_facility_list',
				icon: (h) => h(Icon, { props: { name: 'icon-peitaosheshixiaotubiao_linyu', size: 18 } }),
			},
			{
				name: '政策列表',
				route: 'hotel_policy_list',
				icon: (h) => h(Icon, { props: { name: 'icon-peitaosheshixiaotubiao_linyu', size: 18 } }),
			},
			{
				name: '特色列表',
				route: 'hotel_feature_list',
				icon: (h) => h(Icon, { props: { name: 'icon-peitaosheshixiaotubiao_linyu', size: 18 } }),
			},
			{
				name: '评分维度列表',
				route: 'hotel_score_dimension_list',
				icon: (h) => h(Icon, { props: { name: 'icon-peitaosheshixiaotubiao_linyu', size: 18 } }),
			},
			{
				name: '评价标签列表',
				route: 'hotel_evaluation_label_list',
				icon: (h) => h(Icon, { props: { name: 'icon-24gl-tags', size: 18 } }),
			},
			{
				name: '集团公司列表',
				route: 'hotel_group_company_list',
				icon: (h) => h(Icon, { props: { name: 'icon-peitaosheshixiaotubiao_linyu', size: 18 } }),
			},
		],
	},
	{
		name: '户型管理',
		icon: 'icon-jiudian',
		children: [
			{
				name: '户型列表',
				route: 'room_type_list',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
			{
				name: '设施列表',
				route: 'room_facility_list',
				icon: (h) => h(Icon, { props: { name: 'icon-peitaosheshixiaotubiao_linyu', size: 18 } }),
			},
			{
				name: '标签列表',
				route: 'room_label_list',
				icon: (h) => h(Icon, { props: { name: 'icon-peitaosheshixiaotubiao_linyu', size: 18 } }),
			},
			{
				name: '政策列表',
				route: 'room_policy_list',
				icon: (h) => h(Icon, { props: { name: 'icon-peitaosheshixiaotubiao_linyu', size: 18 } }),
			},
		],
	},
	{
		name: '房间管理',
		icon: 'icon-jiudian',
		children: [
			// {
			// 	name: '房态管理',
			// 	route: 'house_state_manage',
			// 	icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			// },
			{
				name: '房间列表',
				route: 'house_list',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
			{
				name: '楼层列表',
				route: 'house_floor_list',
				icon: (h) => h(Icon, { props: { name: 'icon-peitaosheshixiaotubiao_linyu', size: 18 } }),
			},
			{
				name: '楼号列表',
				route: 'house_building_list',
				icon: (h) => h(Icon, { props: { name: 'icon-peitaosheshixiaotubiao_linyu', size: 18 } }),
			},
			{
				name: '订房必读',
				route: 'house_require_read',
				icon: (h) => h(Icon, { props: { name: 'icon-peitaosheshixiaotubiao_linyu', size: 18 } }),
			},
		],
	},
	{
		name: '订单管理',
		icon: 'icon-jiudian',
		children: [
			{
				name: '订房订单',
				route: 'order_hotel_list',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
			{
				name: '订餐订单',
				route: 'meal_goods_order',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
			{
				name: '早餐订单',
				route: 'breakfast_order',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
			{
				name: '积分商城订单',
				route: 'integral_mall_goods_order',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
			{
				name: '点评列表',
				route: 'remark',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
		],
	},
	{
		name: 'RPA管理',
		icon: 'icon-jiudian',
		children: [
			{
				name: 'OTA订单',
				route: 'ota_order',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
			{
				name: '映射表管理',
				route: 'mapping_table_management',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
		],
	},
	{
		name: '充值卡管理',
		icon: 'icon-jiudian',
		children: [
			{
				name: '储值卡生成',
				route: 'camilo',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
			{
				name: '订单列表',
				route: 'cardsList',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
		],
	},
	{
		name: '用户管理',
		icon: 'icon-jiudian',
		children: [
			{
				name: '用户列表',
				route: 'user_list',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
			// {
			// 	name: '人工充值积分',
			// 	route: 'manual_recharge_points',
			// 	icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			// },
		],
	},
	{
		name: '订餐管理',
		icon: 'icon-jiudian',
		children: [
			{
				name: '订餐商品',
				route: 'meal_goods',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
			{
				name: '商品分类',
				route: 'goods_type',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
		],
	},
	{
		name: '积分商城',
		icon: 'icon-jiudian',
		children: [
			{
				name: '积分商城商品',
				route: 'integral_mall_goods_list',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
		],
	},
	{
		name: '购买记录',
		icon: 'icon-jiudian',
		children: [
			{
				name: '积分购买记录',
				route: 'integral_buy_record',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
			{
				name: '余额充值记录',
				route: 'balance_recharge',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
			{
				name: '余额退款记录',
				route: 'balance_refund',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
		],
	},
	{
		name: '会员',
		icon: 'icon-jiudian',
		children: [
			{
				name: '会员订单',
				route: 'member_order',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
			{
				name: '会员认证信息审核',
				route: 'member_type',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
		],
	},
	{
		name: '系统配置',
		icon: 'icon-jiudian',
		children: [
			{
				name: '系统配置',
				route: 'config_banner',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
		],
	},
	{
		name: '权限配置',
		icon: 'icon-jiudian',
		children: [
			{
				name: '角色列表',
				route: 'permission_role',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
			{
				name: '管理员列表',
				route: 'permission_admin',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
		],
	},
	{
		name: '优惠券',
		icon: 'icon-jiudian',
		children: [
			{
				name: '优惠券列表',
				route: 'coupon_list',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
		],
	},
	{
		name: '活动',
		icon: 'icon-jiudian',
		children: [
			{
				name: '活动列表',
				route: 'activity_list',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
			{
				name: '活动轮播图',
				route: 'activity_banner',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
		],
	},
	{
		name: '消息',
		icon: 'icon-jiudian',
		children: [
			{
				name: '发送消息',
				route: 'send_message',
				icon: (h) => h(Icon, { props: { name: 'icon-weilaijiudian', size: 18 } }),
			},
		],
	},
]

/** 菜单项属性 */
export interface Menu {
	/** 名称 */
	name: string
	/** 路由 */
	route?: string | Location
	/** 跳转外部链接 */
	link?: string
	/** 是否新窗口打开，默认false */
	blank?: boolean
	/** 图标，若是string会使用全局Icon组件（即iconfont图标），否则自定义渲染函数 */
	icon?: string | ((h: CreateElement) => VNode)
	/** 是否禁用 */
	disabled?: boolean
	/** 子菜单 */
	children?: Menu[]
}

export default menus
