import { RouteConfig } from 'vue-router'
import { BreadcrumbList } from '@/store/modules/app'
import ViewMain from '@/common/views/main.vue'

const routes: Array<RouteConfig> = [
	/** 登录 */
	{
		path: '/login',
		name: 'login',
		meta: {
			title: '欢迎登录',
			login: false,
		},
		component: () => import(/* webpackChunkName: "common" */ '@/common/views/login.vue'),
	},

	/** 首页模块 */
	{
		path: '/home',
		alias: '/',
		redirect: { name: 'home' },
		component: ViewMain,
		children: [
			{
				path: 'home',
				name: 'home',
				meta: {
					breadcrumb: [{ name: '首页' }, { name: '首页' }],
				},
				component: () => import(/* webpackChunkName: "home" */ '@/views/home/home.vue'),
			},
		],
	},

	/** 酒店管理 */
	{
		path: '/hotel',
		redirect: { name: 'hotel_list' },
		component: ViewMain,
		children: [
			{
				path: 'list',
				name: 'hotel_list',
				meta: {
					breadcrumb: [{ name: '酒店管理' }, { name: '酒店列表' }],
				},
				component: () => import(/* webpackChunkName: "hotel" */ '@/views/hotel/hotel-list.vue'),
			},
			{
				path: 'label_list',
				name: 'hotel_label_list',
				meta: {
					breadcrumb: [{ name: '酒店管理' }, { name: '标签列表' }],
				},
				component: () => import(/* webpackChunkName: "hotel" */ '@/views/hotel/hotel-label-list.vue'),
			},
			{
				path: 'evaluation_label_list',
				name: 'hotel_evaluation_label_list',
				meta: {
					breadcrumb: [{ name: '酒店管理' }, { name: '评价标签列表' }],
				},
				component: () => import(/* webpackChunkName: "hotel" */ '@/views/hotel/hotel-evaluation-label-list.vue'),
			},
			{
				path: 'facility_list',
				name: 'hotel_facility_list',
				meta: {
					breadcrumb: [{ name: '酒店管理' }, { name: '设施列表' }],
				},
				component: () => import(/* webpackChunkName: "hotel" */ '@/views/hotel/hotel-facility-list.vue'),
			},
			{
				path: 'policy_list',
				name: 'hotel_policy_list',
				meta: {
					breadcrumb: [{ name: '酒店管理' }, { name: '政策列表' }],
				},
				component: () => import(/* webpackChunkName: "hotel" */ '@/views/hotel/hotel-policy-list.vue'),
			},
			{
				path: 'feature_list',
				name: 'hotel_feature_list',
				meta: {
					breadcrumb: [{ name: '酒店管理' }, { name: '特色列表' }],
				},
				component: () => import(/* webpackChunkName: "hotel" */ '@/views/hotel/hotel-feature-list.vue'),
			},
			{
				path: 'score_dimension_list',
				name: 'hotel_score_dimension_list',
				meta: {
					breadcrumb: [{ name: '酒店管理' }, { name: '评分维度列表' }],
				},
				component: () => import(/* webpackChunkName: "hotel" */ '@/views/hotel/hotel-score-dimension-list.vue'),
			},
			{
				path: 'hotel_group_company_list',
				name: 'hotel_group_company_list',
				meta: {
					breadcrumb: [{ name: '酒店管理' }, { name: '集团公司列表' }],
				},
				component: () => import(/* webpackChunkName: "hotel" */ '@/views/hotel/hotel-group-company-list.vue'),
			},
		],
	},
	//户型管理
	{
		path: '/room',
		redirect: { name: 'room_type_list' },
		component: ViewMain,
		children: [
			{
				path: 'type_list',
				name: 'room_type_list',
				meta: {
					breadcrumb: [{ name: '户型管理' }, { name: '户型列表' }],
				},
				component: () => import(/* webpackChunkName: "room" */ '@/views/room/room-type-list.vue'),
			},
			{
				path: 'facility_list',
				name: 'room_facility_list',
				meta: {
					breadcrumb: [{ name: '户型管理' }, { name: '设施列表' }],
				},
				component: () => import(/* webpackChunkName: "room" */ '@/views/room/room-facility-list.vue'),
			},
			{
				path: 'label_list',
				name: 'room_label_list',
				meta: {
					breadcrumb: [{ name: '户型管理' }, { name: '标签列表' }],
				},
				component: () => import(/* webpackChunkName: "room" */ '@/views/room/room-label-list.vue'),
			},
			{
				path: 'policy_list',
				name: 'room_policy_list',
				meta: {
					breadcrumb: [{ name: '户型管理' }, { name: '政策列表' }],
				},
				component: () => import(/* webpackChunkName: "room" */ '@/views/room/room-policy-list.vue'),
			},
		],
	},
	//房间管理
	{
		path: '/house',
		redirect: { name: 'house_list' },
		component: ViewMain,
		children: [
			{
				path: 'state_manage',
				name: 'house_state_manage',
				meta: {
					breadcrumb: [{ name: '房间管理' }, { name: '房态管理' }],
				},
				component: () => import(/* webpackChunkName: "house" */ '@/views/house/house-state-manage.vue'),
			},
			{
				path: 'house',
				name: 'house_list',
				meta: {
					breadcrumb: [{ name: '房间管理' }, { name: '房间列表' }],
				},
				component: () => import(/* webpackChunkName: "house" */ '@/views/house/house.vue'),
			},
			{
				path: 'floor_list',
				name: 'house_floor_list',
				meta: {
					breadcrumb: [{ name: '房间管理' }, { name: '楼层列表' }],
				},
				component: () => import(/* webpackChunkName: "house" */ '@/views/house/house_floor_list.vue'),
			},
			{
				path: 'building_list',
				name: 'house_building_list',
				meta: {
					breadcrumb: [{ name: '房间管理' }, { name: '楼号列表' }],
				},
				component: () => import(/* webpackChunkName: "house" */ '@/views/house/house-building-list.vue'),
			},
			{
				path: 'require_read',
				name: 'house_require_read',
				meta: {
					breadcrumb: [{ name: '房间管理' }, { name: '订房必读' }],
				},
				component: () => import(/* webpackChunkName: "house" */ '@/views/house/house-require-read.vue'),
			},
		],
	},
	// 订单管理
	{
		path: '/order',
		redirect: { name: 'order_hotel_list' },
		component: ViewMain,
		children: [
			{
				path: 'order_hotel_list',
				name: 'order_hotel_list',
				meta: {
					breadcrumb: [{ name: '订单管理' }, { name: '订房订单' }],
				},
				component: () => import(/* webpackChunkName: "order" */ '@/views/order/hotel-order.vue'),
			},
			{
				path: 'meal_goods_order',
				name: 'meal_goods_order',
				meta: {
					breadcrumb: [{ name: '订单管理' }, { name: '订餐订单' }],
				},
				component: () => import(/* webpackChunkName: "order" */ '@/views/order/meal-goods-order.vue'),
			},
			{
				path: 'breakfast_order',
				name: 'breakfast_order',
				meta: {
					breadcrumb: [{ name: '订单管理' }, { name: '早餐订单' }],
				},
				component: () => import(/* webpackChunkName: "order" */ '@/views/order/breakfast-order.vue'),
			},
			{
				path: 'integral_mall_goods_order',
				name: 'integral_mall_goods_order',
				meta: {
					breadcrumb: [{ name: '订单管理' }, { name: '积分商城订单' }],
				},
				component: () => import(/* webpackChunkName: "order" */ '@/views/order/integral-mall-goods-order.vue'),
			},
			{
				path: 'remark',
				name: 'remark',
				meta: {
					breadcrumb: [{ name: '订单管理' }, { name: '点评列表' }],
				},
				component: () => import(/* webpackChunkName: "order" */ '@/views/order/remark.vue'),
			},
		],
	},
	//RPA管理
	{
		path: '/ota',
		redirect: { name: 'ota' },
		component: ViewMain,
		children: [
			{
				path: 'ota_order',
				name: 'ota_order',
				meta: {
					breadcrumb: [{ name: 'RPA管理' }, { name: 'OTA订单' }],
				},
				component: () => import(/* webpackChunkName: "ota" */ '@/views/ota/ota-order.vue'),
			},
			{
				path: 'mapping_table_management',
				name: 'mapping_table_management',
				meta: {
					breadcrumb: [{ name: 'RPA管理' }, { name: '映射表管理' }],
				},
				component: () => import(/* webpackChunkName: "ota" */ '@/views/ota/mapping-table-management.vue'),
			},
		],
	},
	{
		path: '/user',
		redirect: { name: 'order_hotel_list' },
		component: ViewMain,
		children: [
			{
				path: 'list',
				name: 'user_list',
				meta: {
					breadcrumb: [{ name: '用户管理' }, { name: '用户列表' }],
				},
				component: () => import(/* webpackChunkName: "user" */ '@/views/user/user-list.vue'),
			},
			// {
			// 	path: 'manual_recharge_points',
			// 	name: 'manual_recharge_points',
			// 	meta: {
			// 		breadcrumb: [{ name: '用户管理' }, { name: '人工充值积分' }],
			// 	},
			// 	component: () => import(/* webpackChunkName: "order" */ '@/views/user/manual-recharge-points.vue'),
			// },
		],
	},
	//订餐管理
	{
		path: '/meal',
		redirect: { name: 'meal_goods' },
		component: ViewMain,
		children: [
			{
				path: 'meal_goods',
				name: 'meal_goods',
				meta: {
					breadcrumb: [{ name: '订餐管理' }, { name: '订餐商品' }],
				},
				component: () => import(/* webpackChunkName: "meal" */ '@/views/meal/mealGoodsList.vue'),
			},
			{
				path: 'goods_type',
				name: 'goods_type',
				meta: {
					breadcrumb: [{ name: '订餐管理' }, { name: '商品分类' }],
				},
				component: () => import(/* webpackChunkName: "meal" */ '@/views/meal/goodsType.vue'),
			},
			{
				path: 'goods_sku',
				name: 'goods_sku',
				meta: {
					breadcrumb: [{ name: '订餐管理' }, { name: '订餐商品' }, { name: '订餐商品SKU' }],
				},
				component: () => import(/* webpackChunkName: "meal" */ '@/views/meal/mealGoodsSku.vue'),
			},
		],
	},
	//积分商城
	{
		path: '/integral',
		redirect: { name: 'meal_goods' },
		component: ViewMain,
		children: [
			{
				path: 'integral_mall_goods_list',
				name: 'integral_mall_goods_list',
				meta: {
					breadcrumb: [{ name: '积分商城' }, { name: '积分商城商品' }],
				},
				component: () => import(/* webpackChunkName: "integral" */ '@/views/Integral/integral-mall-goods-list.vue'),
			},
		],
	},
	// 购买退款记录
	{
		path: '/record',
		redirect: { name: 'buy_record' },
		component: ViewMain,
		children: [
			{
				path: 'integral_buy_record',
				name: 'integral_buy_record',
				meta: {
					breadcrumb: [{ name: '购买记录' }, { name: '积分购买记录' }],
				},
				component: () => import(/* webpackChunkName: "integral" */ '@/views/record/integral-buy-record.vue'),
			},
			{
				path: 'balance_recharge',
				name: 'balance_recharge',
				meta: {
					breadcrumb: [{ name: '购买记录' }, { name: '余额充值记录' }],
				},
				component: () => import(/* webpackChunkName: "integral" */ '@/views/record/balance-recharge.vue'),
			},
			{
				path: 'balance_refund',
				name: 'balance_refund',
				meta: {
					breadcrumb: [{ name: '购买记录' }, { name: '退款申请列表' }],
				},
				component: () => import(/* webpackChunkName: "integral" */ '@/views/record/balance-refund.vue'),
			},
		],
	},
	{
		path: '/config',
		redirect: { name: 'config_banner' },
		component: ViewMain,
		children: [
			{
				path: 'config_banner',
				name: 'config_banner',
				meta: {
					breadcrumb: [{ name: '系统配置' }, { name: '系统配置' }],
				},
				component: () => import(/* webpackChunkName: "config" */ '@/views/Configs/config-banner.vue'),
			},
		],
	},
	{
		path: '/permission',
		redirect: { name: 'permission_role' },
		component: ViewMain,
		children: [
			{
				path: 'permission_role',
				name: 'permission_role',
				meta: {
					breadcrumb: [{ name: '权限配置' }, { name: '角色配置' }],
				},
				component: () => import(/* webpackChunkName: "config" */ '@/views/permission/permission-role.vue'),
			},
			{
				path: 'permission_admin',
				name: 'permission_admin',
				meta: {
					breadcrumb: [{ name: '权限配置' }, { name: '管理员列表' }],
				},
				component: () => import(/* webpackChunkName: "config" */ '@/views/permission/permission-admin.vue'),
			},
		],
	},
	{
		path: '/member',
		redirect: { name: 'member_order' },
		component: ViewMain,
		children: [
			{
				path: 'member_order',
				name: 'member_order',
				meta: {
					breadcrumb: [{ name: '会员' }, { name: '会员订单' }],
				},
				component: () => import(/* webpackChunkName: "config" */ '@/views/member/member-order.vue'),
			},
			{
				path: 'member_type',
				name: 'member_type',
				meta: {
					breadcrumb: [{ name: '会员' }, { name: '会员认证信息审核' }],
				},
				component: () => import(/* webpackChunkName: "config" */ '@/views/member/member-type.vue'),
			},
		],
	},
	{
		path: '/coupon',
		redirect: { name: 'coupon_list' },
		component: ViewMain,
		children: [
			{
				path: 'coupon_list',
				name: 'coupon_list',
				meta: {
					breadcrumb: [{ name: '优惠券' }, { name: '优惠券列表' }],
				},
				component: () => import(/* webpackChunkName: "config" */ '@/views/coupon/coupon-list.vue'),
			},
		],
	},
	{
		path: '/activity',
		redirect: { name: 'activity_list' },
		component: ViewMain,
		children: [
			{
				path: 'activity_list',
				name: 'activity_list',
				meta: {
					breadcrumb: [{ name: '活动' }, { name: '活动列表' }],
				},
				component: () => import(/* webpackChunkName: "config" */ '@/views/activity/activity-list.vue'),
			},
			{
				path: 'activity_banner',
				name: 'activity_banner',
				meta: {
					breadcrumb: [{ name: '活动轮播图' }, { name: '活动轮播图' }],
				},
				component: () => import(/* webpackChunkName: "config" */ '@/views/activity/activity-banner.vue'),
			},
		],
	},
	{
		path: '/message',
		redirect: { name: 'message_list' },
		component: ViewMain,
		children: [
			{
				path: 'send_message',
				name: 'send_message',
				meta: {
					breadcrumb: [{ name: '消息' }, { name: '发送消息' }],
				},
				component: () => import(/* webpackChunkName: "config" */ '@/views/message/send-message.vue'),
			},
		],
	},
	//充值卡管理
	{
		path: '/Recharge',
		redirect: { name: 'Recharge_list' },
		component: ViewMain,
		children: [
			{
				path: 'camilo',
				name: 'camilo',
				meta: {
					breadcrumb: [{ name: '充值卡管理' }, { name: '储值卡生成' }],
				},
				component: () => import(/* webpackChunkName: "integral" */ '@/views/Recharge/camilo.vue'),
			},
			{
				path: 'cardsList',
				name: 'cardsList',
				meta: {
					breadcrumb: [{ name: '充值卡管理' }, { name: '订单列表' }],
				},
				component: () => import(/* webpackChunkName: "integral" */ '@/views/Recharge/cardsList.vue'),
			},
		],
	},
]

/** 路由meta属性 */
export interface RouteMeta {
	/** 标题，如果设置title，router守卫会据此来设置网页的title，undefined则不处理 */
	title?: string | number
	/** 是否要求登录，undefined会被视为true，本参数在router守卫中使用并处理 */
	login?: boolean
	/** 面包屑配置，本参数在router守卫中使用并处理 */
	breadcrumb?: BreadcrumbList
	/** 页面是否keep-alive */
	keepalive?: boolean
}

export default routes
