import Vue from 'vue'
import Vuex from 'vuex'

import app, { State as AppState } from './modules/app'
import admin, { State as AdminState } from './modules/admin'
import order, { State as OrderState } from './modules/order'

Vue.use(Vuex)

export default new Vuex.Store<{
	app: AppState
	admin: AdminState
	order: OrderState
}>({
	modules: { app, admin, order },
})
