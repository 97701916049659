import { AxiosResponse, AxiosError } from 'axios'
import { RequestConfig, ResponseCommonData, ResponseServiceError } from './index'
import { Message } from 'element-ui'
import router from '@/router/index'

import store from '@/store'

const showErrorMessage = (msg: string, code?: number | string) => Message.error(`${msg}${code ? `` : ''}`)

export const afterResponse = async <ResponseDataType = any>(config: RequestConfig, response?: AxiosResponse): Promise<ResponseCommonData<ResponseDataType>> => {
	if (config.throw_service_failed !== false && response) {
		// 业务错误，处理自动抛出异常和消息

		if (response.data.code != 0) {
			// window.localStorage.setItem('num', '0')
			if (response.data.code == 403 && localStorage.getItem('num') == '0' && response.config.url != `/adminapi/Admin/getAdminInfo`) {
				Message.error('身份无效，请重新登录')
				window.localStorage.setItem('num', '1')
				setTimeout(() => {
					return location.reload()
				}, 800)
			} else {
				let error = new Error(response.data.msg) as ResponseServiceError
				error.code = response.data.code
				error.name = 'ResponseServiceError'
				error.config = config
				error.response = response
				error.isResponseServiceError = true
				return Promise.reject(error)
			}
		}
	}
	return Promise.resolve(response?.data)
}

export const afterError = async (config: RequestConfig, error: ResponseServiceError | AxiosError) => {
	if (config.auto_message_when_error !== false) showErrorMessage(error.message, error.code)
	return Promise.reject(error)
}
