













import Vue, { PropType } from 'vue'
/**
 * 配合 useTable 使用的分页组件
 */
export default Vue.extend({
	props: {
		query: Object,
		state: Object,
		pageSize: {
			type: Array,
			default: () => [10, 20, 30, 50],
		},
	},
})
