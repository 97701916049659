


































import Vue from 'vue'
import { mapState } from 'vuex'
export default Vue.extend({
	props: {
		/** 左侧是否折叠 */
		siderCollapse: {
			type: Boolean,
			default: false,
		},
		/** 是否小屏模式 */
		miniMode: {
			type: Boolean,
			default: false,
		},
	},
	// emits:[
	// 	'sider-collapse', //点击左侧导航折叠展开按钮 (collapse: 预期结果)
	// 	'refresh', //点击刷新 ()
	// ],
	data() {
		return {
			fullscreen: {
				is_full_screen: false,
			},
		}
	},
	computed: {
		...mapState<any>('admin', {
			admin_info: (state) => state.info,
		}),
		...mapState<any>('app', {
			breadcrumb: (state) => state.breadcrumb,
		}),
	},
	methods: {
		onClickAdminBox(cmd) {
			if (cmd == '修改密码') {
				// [TODO]
			} else if (cmd == '退出登陆') {
				this.$store.dispatch('admin/logout').then(() => {
					this.$router.replace({ name: 'login' })
					this.$message.success('已安全退出')
				})
			}
		},
		onClickBreadcrumb(b) {
			if (b.route) this.$router.push(typeof b.route == 'string' ? { name: b.route } : b.route)
			else if (b.link) window.location.href = b.link
		},
		onClickRefresh() {
			;(this.$refs.refreshIconRef as HTMLElement)?.classList.add('rotate')
			setTimeout(() => {
				;(this.$refs.refreshIconRef as HTMLElement)?.classList.remove('rotate')
			}, 400)
			this.$emit('refresh')
		},
	},
})
