import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from './routes'
import { beforeEach, afterEach } from './guard'

Vue.use(VueRouter)

const router = new VueRouter({
	routes,
})

router.beforeEach(beforeEach)
router.afterEach(afterEach)

export default router
