import router from '@/router'

/**
 * 设置页面标题
 * @param title 标题
 */
export const setTitle = (title = '') => {
	document.title = title
}

/**
 * 页面返回上一级
 * 如果没有上一级页面，则返回指定的pathname
 * @param {string} pathname 页面的name
 */
export const goBack = (pathname = 'home') => {
	if (window.history.length <= 1) {
		router.push({ name: pathname })
	} else {
		router.go(-1)
	}
}

/**
 * 触发或退出全屏
 * @param onoff 全屏或关闭全屏
 * @param element 要全屏的元素，默认是整个body
 */
export const troggleFullScreen = (onoff, element: HTMLElement | null = null) => {
	if (element == null) element = document.body
	if (!onoff) document.exitFullscreen()
	else element.requestFullscreen()
}

interface DataList {
	value: string | number
	label: string
	children: DataList[]
	[props: string]: any
}
/**
 * 根据id获取树中对应的名称
 * @param list 数据源
 * @param value 传入的id
 * @returns
 */
export const getTreeName = (list: DataList[], value: string | number) => {
	for (let i = 0; i < list.length; i++) {
		let item = list[i]
		if (item.value === value) {
			return item.label
		} else {
			if (item.children && item.children.length > 0) {
				let res = getTreeName(item.children, value)
				if (res) {
					return res
				}
			}
		}
	}
}
