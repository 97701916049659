import Vue from 'vue'
import * as Tools from './index'

// 定义Vue的this类型，必须引入Vue，否则无效
declare module 'vue/types/vue' {
	interface Vue {
		$tools: typeof Tools
	}
}

export default {
	install(vue) {
		vue.prototype.$tools = Tools
	},
}
