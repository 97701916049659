



















































import Vue from 'vue'
import NavBar from '@/common/components/main/nav-bar.vue'
import SiderMenu from '@/common/components/main/sider-menu.vue'
import store from '@/store'
import { mapState } from 'vuex'
export default Vue.extend({
	components: { NavBar, SiderMenu },
	provide: {
		oss23: '?x-oss-process=image/resize,h_30,m_lfit',
		oss50: '?x-oss-process=image/resize,h_50,m_lfit',
		oss56: '?x-oss-process=image/resize,h_56,m_lfit',
		oss179: '?x-oss-process=image/resize,h_132,m_lfit',
		oss140: '?x-oss-process=image/resize,h_140,m_lfit',
		//inject: ['oss56'],
	},
	data() {
		return {
			layout: {
				navbar_height: 56,
				sider_width: 240,
				sider_collapse_width: 64,
				sider_color: '#191a23',
				sider_text_color: '#fff',
				sider_collapse: store.state.app.device_info.type != 'pc',
			},
			refresh: {
				is_hide: false,
			},
		}
	},
	computed: {
		...mapState<any>('app', {
			/** 小屏幕模式，影响导航、面包屑隐藏等 */
			is_mini_mode: (state) => state.device_info.type != 'pc',
		}),
	},
	methods: {},
})
