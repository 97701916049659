import { browser } from 'llm-web-tool'
import { Location } from 'vue-router'
import { MutationTree } from 'vuex'
import store from '../index'

// == 类型定义==

/** 面包屑导航 */
export type BreadcrumbItem = {
	/**
	 * 标题
	 */
	name: string | number
	/**
	 * 路由跳转对象或name
	 */
	route?: string | Location
	/**
	 * 外部链接
	 */
	link?: string
}
/** 面包屑导航 */
export type BreadcrumbList = Array<BreadcrumbItem | string>
/** 面包屑导航 */
export type Breadcrumb = {
	separator: string
	list: BreadcrumbList
}

/** 设备信息 */
export type DeviceInfo = {
	/**
	 * 设备类型(pc/mobile/pad)
	 */
	type: 'pc' | 'mobile' | 'pad'
	/**
	 * 屏幕宽度
	 */
	width: number
	/**
	 * 屏幕高度
	 */
	height: number
}

export type State = {
	device_info: DeviceInfo
	breadcrumb: Breadcrumb
}

// 监听屏幕尺寸变化
window.addEventListener('resize', () => {
	store.commit('app/setDeviceInfoSize', { width: window.innerWidth, height: window.innerHeight })
})

// == store定义 ==
const state: State = {
	device_info: {
		type: browser.isPC() ? 'pc' : browser.isMobile() ? 'mobile' : 'pad',
		width: window.innerWidth,
		height: window.innerHeight,
	},
	breadcrumb: {
		separator: '/',
		list: [],
	},
}
const mutations = {
	/** 设置屏幕尺寸 */
	setDeviceInfoSize(state, payload: { width: number; height: number }) {
		state.device_info.width = payload.width
		state.device_info.height = payload.height
	},
	/** 设置面包屑导航 */
	setBreadCurmbList(state, payload?: BreadcrumbList) {
		state.breadcrumb.list = payload ?? []
	},
}
const actions = {}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
}
