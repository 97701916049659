













import Vue from 'vue'
import { cloneDeep, assign } from 'lodash'
export default Vue.extend({
	props: {
		/** 模型名，会自动拼接到title上 */
		name: String,
		formdata: {
			type: Object,
			default: () => ({}),
		},
		visible: {
			type: Boolean,
			default: false,
		},
		nosub: {
			type: Boolean,
			default: false,
		},
		/** 提交事件 */
		onSubmit: Function,
	},
	// emits:[
	// 	'update:visible(show)', // 更新显示状态
	// 	'update:formdata(formdata)', // 更新表单值
	//	'submit(formdata)' 提交，收到的formdata是经过cloneDeep()的，可以直接操作
	// ],
	data() {
		return {
			cloneDeep,
			/** 受控模式显示状态 */
			my_visible: false,
			submit_loading: false,
			/** 初始表单值，用于充值表单值 */
			init_formdata: {},
		}
	},
	computed: {
		title() {
			return this.$attrs.title ?? `${this.formdata?.id ? '编辑' : '新增'}${this.name}`
		},
		width() {
			return this.$attrs.width ?? this.$G_dialog_auto_width
		},
	},
	watch: {
		visible: {
			immediate: true,
			handler(v) {
				if (v != this.my_visible) this.my_visible = v
			},
		},
		my_visible(v) {
			if (this.visible != v) this.$emit('update:visible', v)
		},
	},
	methods: {
		/**
		 * 外部访问，打开的方法，可传入一个表单来设置表单内容
		 * @param row formdata数据
		 * @param onoff 是否打开弹窗
		 */
		open(row?, onoff = true) {
			this.$emit('update:formdata', row ? cloneDeep(assign({}, this.formdata, cloneDeep(row))) : cloneDeep(this.init_formdata))
			this.my_visible = onoff
		},
		onEditFormSubmit() {
			this.$emit('submit')
			if (this.nosub) {
				this.open(undefined, false)
			}
			if (this.onSubmit) {
				this.submit_loading = true
				this.onSubmit(cloneDeep(this.formdata))
					.then(() => this.open(undefined, false))
					.finally(() => (this.submit_loading = false))
			}
		},
	},
	created() {
		this.init_formdata = cloneDeep(this.formdata)
	},
})
