import { adminToken } from '@/libs/local-store'
import { cloneDeep } from 'lodash'
import { getAdminInfo } from '@/api/admin'

// == 类型定义==

/** 管理员模型 */
export type AdminInfo = {
	id: number
	name: string
	account: string
}

export type State = {
	info: any
}

const state: State = {
	info: null,
}
const mutations = {
	/**
	 * 设置管理员信息
	 */
	setInfo(state, payload: AdminInfo | null = null) {
		state.info = payload
	},
}
const actions = {
	updateInfo(
		{ commit },
		payload?: {
			/**
			 * 是否显示失败提示
			 */
			show_message?: boolean
		}
	) {
		return getAdminInfo({ auto_message_when_error: payload?.show_message ?? true }).then((res) => {
			if (res.code) throw Error(res.msg)
			commit('setInfo', res.data)
			return cloneDeep(res.data)
		})
	},
	logout({ commit }) {
		commit('setInfo', null)
		adminToken.get(true)
		return Promise.resolve()
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
}
